import { forwardRef } from 'react';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

export const CognitoLoginButton = forwardRef(({ label, onClick }, ref) => (
  <button className="gsi-material-button" onClick={onClick} type="button" ref={ref} style={{ border: '1px solid rgba(63, 80, 181, 0.5)' }}>
    <div className="gsi-material-button-state" />
    <div className="gsi-material-button-content-wrapper">
      <div className="gsi-material-button-icon">
        <AlternateEmailIcon />
      </div>
      <span className="gsi-material-button-contents">{label}</span>
    </div>
  </button>
));

CognitoLoginButton.displayName = 'CognitoLoginButton';
