import { Box, Button, Container, Divider, Grid, Link, Paper, Stack, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { ROUTES } from '../../routes';
import { UserContext } from '../../contexts/UserContext';
import { EDLINK_AUTH_URL, GOOGLE_SIGN_IN_URL, COGNITO_LOGIN_URL } from '../../config';
import { GoogleLoginButton } from './GoogleLoginButton';
import { CodeLogin } from './CodeLogin';
import ClassroomLogo from '../../assets/classroom.png';
import { ReactComponent as CanvasLogo } from '../../assets/canvas.svg';
import { ReactComponent as SchoologyLogo } from '../../assets/schoology.svg';
import { CognitoLoginButton } from './CognitoLoginButton';

// auth VS login url
const cognitoLogin = () => window.open(COGNITO_LOGIN_URL, '_self');
const edLinkLogin = () => window.open(EDLINK_AUTH_URL, '_self');
const googleSignIn = () => window.open(GOOGLE_SIGN_IN_URL, '_self');

const useMessages = () => {
  const [searchParams] = useSearchParams();
  const message = searchParams.get('message');
  const navigate = useNavigate();

  useEffect(() => {
    switch (message) {
      case 'code-login-failed':
        enqueueSnackbar('Access code login failed. Please try again.', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        navigate('/', { replace: true });
        break;
      default:
      // Do nothing
    }
  }, [message]);
};

// TODO: Relocate to dedicated file with styles?
export const commonLoginWindowStyle = {
  padding: '18px',
  textAlign: 'center',
  height: '100%',
};

export function HomePage() {
  const user = useContext(UserContext); // TODO: Consider create useUserContext
  useMessages();


  if (user) {
    if (user.isTeacher) {
      return <Navigate replace to={ROUTES.ASSIGNMENTS_INDEX} />;
    }

    if (user.isAdmin) {
      return <Navigate replace to={ROUTES.ADMIN_USERS} />;
    }

    return <Navigate replace to={ROUTES.STUDENT_DASHBOARD} />;
  }

  return (
    <Container>
      <Typography
        variant="h4"
        component="h2"
        color="text.main"
        textAlign="center"
        sx={{ margin: 3, color: 'grey.600' }}
      >
        Sign In
      </Typography>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={6}>
          <Paper elevation={3} style={{ ...commonLoginWindowStyle }}>
            <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
              <Typography variant="h6">With School Account</Typography>
              <Button onClick={edLinkLogin} variant="outlined" sx={{ padding: '5px 7px' }}>
                <img src={ClassroomLogo} alt="Google Classroom Logo" style={{ height: '29px' }} />
                <CanvasLogo style={{ width: '74px', marginLeft: '3px', marginTop: '4px' }} />
                <SchoologyLogo
                  style={{
                    width: '110px',
                    height: '28px',
                    marginLeft: '13px',
                  }}
                />
              </Button>
            </Box>
            <Divider style={{ margin: '10px 0', border: 'none' }} />
            <CodeLogin />
            <Divider style={{ margin: '10px 0', border: 'none' }} />
            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
              <Typography variant="h6">With</Typography>
              <GoogleLoginButton label="Google" onClick={googleSignIn} />
            </Stack>
            <Divider style={{ margin: '10px 0', border: 'none' }} />
            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
              <Typography variant="h6">With</Typography>
              <CognitoLoginButton label="Email" onClick={cognitoLogin} />
            </Stack>
            <Divider light style={{ margin: '20px 0 0 0' }} textAlign="center">
              <Typography variant="body1" color="textSecondary">
                or
              </Typography>
            </Divider>
            <Box display="flex" justifyContent="center" mt={1}>
              <Link href="/sign-up" variant="body">
                Sign Up
              </Link>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
